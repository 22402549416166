import React from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LoadScript from './LoadScript';

const loadScripts = () => {
  LoadScript(`${process.env.PUBLIC_URL}/assets/js/layout.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/libs/bootstrap/js/bootstrap.bundle.min.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/libs/simplebar/simplebar.min.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/libs/node-waves/waves.min.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/libs/feather-icons/feather.min.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/js/pages/plugins/lord-icon-2.1.0.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/js/plugins.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/libs/apexcharts/apexcharts.min.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/libs/jsvectormap/js/jsvectormap.min.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/libs/jsvectormap/maps/world-merc.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/js/pages/dashboard-analytics.init.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/libs/list.js/list.min.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/libs/list.pagination.js/list.pagination.min.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/libs/swiper/swiper-bundle.min.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/js/pages/crypto-transactions.init.js`);
  LoadScript(`${process.env.PUBLIC_URL}/assets/js/app.js`);

}

const Root = () => {
    useEffect(() => {
      loadScripts();
    }, []);
  
    return <App />;
  }
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Root />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
