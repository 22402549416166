import React from 'react'
import _ from "lodash";

function TransactionsContent() {
    return (

        <div className="card" id="contactList">
            <div className="card-header">
                <div className="row align-items-center g-3">
                    <div className="col-md-5">
                        <h5 className="card-title mb-0">All Transactions</h5>
                    </div>
                    <div className="col-md-7">
                        <div className="search-box">
                            <input type="text" className="form-control search" placeholder="Search for transaction hash / height / type / status / amount / fee / time" />
                            <i className="ri-search-line search-icon"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="table-responsive table-card">
                    <table className="table align-middle table-nowrap" id="transactions">
                        <thead className="table-light text-muted">
                            <tr>
                                <th className="sort" data-sort="transaction_id" scope="col">Tx Hash</th>
                                <th className="sort" data-sort="block_height" scope="col">Height</th>
                                <th className="sort" data-sort="type" scope="col">Type</th>
                                <th className="sort" data-sort="status" scope="col">Status</th>
                                <th className="sort" data-sort="amount" scope="col">Amount</th>
                                <th className="sort" data-sort="fee" scope="col">Fee</th>
                                <th className="sort" data-sort="time" scope="col">Time</th>
                            </tr>

                        </thead>
                        <tbody className="list form-check-all">
                        {_.times(12, (i) => (
                            <tr key={"btn"+(i+1)} id={"btn"+(i+1)}>
                                <td className="transaction_id"><a href='tx/81E0B16C1A537AC80FA4763131D25F6EDD52AB57161DF564D27DE99B2F3DBBA1'>81E0B16C1A537..........27DE99B2F3DBB</a></td>
                                <td className="block_height"><a href='block/12345'>12345</a></td>
                                <td className="type"><span className="badge badge-soft-dark badge-border">Deposit</span></td>
                                <td className="status">
                                    <span className={i%2===0 ? "badge badge-soft-danger fs-11" : "badge badge-soft-success fs-11"}><i className="ri-close-circle-line align-bottom"></i> {i%2===0? 'Failed' : 'Success'}</span>
                                </td>
                                <td>
                                    <p className="mb-0 amount">{(i+1)*13.523} <span style={{ color: "red" }}>TOKEN</span></p>
                                </td>
                                <td>
                                    <p className="mb-0 fee">{(i+1)*0.000145}  <span style={{ color: "red" }}>TOKEN</span></p>
                                </td>
                                <td className="time">04 Jan, 2022 <small className="text-muted">02:24AM</small></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                            <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{ width: "75px", height: "75px" }}>
                            </lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            <p className="text-muted mb-0">We've searched more than 150+ transactions We did not find any transactions for you search.</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <div className="pagination-wrap hstack gap-2">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="page-item pagination-prev disabled" href="#">
                            Previous
                        </a>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="page-item pagination-next" href="#">
                            Next
                        </a>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TransactionsContent