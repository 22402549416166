import React from 'react'
import _ from "lodash";

function BlocksContent() {
    return (

        <div className="card" id="contactList">
            <div className="card-header">
                <div className="row align-items-center g-3">
                    <div className="col-md-5">
                        <h5 className="card-title mb-0">All Blocks</h5>
                    </div>
                    <div className="col-md-7">
                        <div className="search-box">
                            <input type="text" className="form-control search" placeholder="Search for height / block hash / proposer / amount / time" />
                            <i className="ri-search-line search-icon"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="table-responsive table-card">
                    <table className="table align-middle table-nowrap" id="blocks">
                        <thead className="table-light text-muted">
                            <tr>
                                <th className="sort" data-sort="block_height" scope="col">Height</th>
                                <th className="sort" data-sort="transaction_id" scope="col">Block Hash</th>
                                <th className="sort" data-sort="status" scope="col">Proposer</th>
                                <th className="sort" data-sort="amount" scope="col">Amount</th>
                                <th className="sort" data-sort="time" scope="col">Time</th>
                            </tr>

                        </thead>
                        <tbody className="list form-check-all">
                        {_.times(7, (i) => (
                            <tr key={"btn"+(i+1)} id={"btn"+(i+1)}>
                                <td className="block_height"><a href={'block/'+(i+500000)}>{i+500000}</a></td>
                                <td className="transaction_id"><a href={'block/'+(i+500000)}>81E0B16C1A537..........27DE99B2F3DBB</a></td>
                                <td className="type"><a href="validator/xxxx"><span className="badge badge-soft-dark badge-border">Foundation Node {i+1}</span></a></td>
                                <td>
                                    <p className="mb-0 amount">{(i+3)*(i*i/2)}</p>
                                </td>
                                <td className="time">04 Jan, 2022 <small className="text-muted">02:24AM</small></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                            <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{ width: "75px", height: "75px" }}>
                            </lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            <p className="text-muted mb-0">We've searched more than 150+ transactions We did not find any transactions for you search.</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <div className="pagination-wrap hstack gap-2">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="page-item pagination-prev disabled" href="#">
                            Previous
                        </a>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="page-item pagination-next" href="#">
                            Next
                        </a>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BlocksContent