import React, { useEffect, useState } from 'react';
import axios from 'axios';

function InfoCard({ name, icon, avatarClass}){

    const API_Link="https://api-omniflixhub-ia.cosmosia.notional.ventures/cosmos/base/tendermint/v1beta1/blocks/latest";
    
    const [latestHeight, setLatestHeight] = useState([]);
  
    useEffect(() => {
        const timerId = setInterval(() => {
            axios.get(API_Link)
            .then((response) => setLatestHeight(response.data.block?.last_commit?.height))
            .catch(error => console.error(error));
        }, 2000 );

        return () => clearInterval(timerId);

    }, []);

    return (
        <div className="card card-animate">
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <div>
                        <p className="fw-medium text-muted mb-0">{name}</p>
                        <h2 className="mt-4 ff-secondary fw-semibold">
                            <span className="counter-value">{Number(latestHeight).toLocaleString('en-US')}</span>
                        </h2>
                    </div>
                    <div>
                        <div className="avatar-sm flex-shrink-0">
                            <span className={avatarClass}>
                                <i className={icon}></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoCard
