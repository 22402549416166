import React from 'react'
import _ from "lodash";

function Uptime() {
  return (
    <div>
        <div className="card card-height-100">
            <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Uptime</h4>
                <div className="flex-shrink-0">
                    <div className="dropdown card-header-dropdown">
                        <span className="text-muted">Last 100 Blocks</span>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="hstack flex-wrap gap-2">
                    {_.times(100, (i) => (
                        <button key={'uptime'+i} style={{height: 'calc(1rem + 1em)', width: 'calc(1rem + 1em)'}} type="button" className="btn btn-success btn-icon waves-effect waves-light" data-bs-toggle="tooltip" data-bs-placement="top" title={i+1}>
                            <span className='bg-success'></span>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Uptime
