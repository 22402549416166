import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Blocks from './pages/Blocks';
import NoPage from './pages/NoPage';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={ <Dashboard />} />
          <Route path='/blocks' element={ <Blocks /> } exact/>
          <Route path='*' element={ <NoPage /> } />         
        </Routes>
      </BrowserRouter>
  );
}

export default App;