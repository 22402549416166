import React, { Fragment } from 'react'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import NotificationModel from '../components/NotificationModel'
import UsersbyDevice from '../components/UsersbyDevice'
import TopReferrals from '../components/TopReferrals'
import ThemeSettings from '../components/ThemeSettings'
import UpgradeMessage from '../components/UpgradeMessage'
import Breadcrumb from '../components/Breadcrumb'
import TransactionsContent from '../components/TransactionsContent'
import Uptime from '../components/Uptime'
import InfoCard from '../components/InfoCard'

function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

class Dashboard extends React.Component {
    render() {
        return (
            <Fragment>
                <div id="layout-wrapper">
                    <Header />
                    <NotificationModel />
                    <Sidebar />
                    <div className="vertical-overlay"></div>
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <Breadcrumb title="Analytics" route1="Dashboards" currentPage="Analytics" />
                                <div className="row">
                                    <div className="col-xxl-12">
                                        <div className="d-flex flex-column h-100">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <UpgradeMessage />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                        <InfoCard name="Height" icon="las la-cubes" avatarClass="avatar-title bg-primary rounded-circle fs-2" />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <InfoCard name="Transactions" icon="las la-sync" avatarClass="avatar-title bg-primary rounded-circle fs-2" />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <InfoCard name="Inflation" icon="mdi mdi-trending-up" avatarClass="avatar-title bg-primary rounded-circle fs-2" />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <InfoCard name="Staking Rewards" icon="las la-gifts" avatarClass="avatar-title bg-primary rounded-circle fs-2" />
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <Uptime />
                                    </div>
                                </div>
                                <div className="row">
                                    <UsersbyDevice />
                                    <TopReferrals />
                                </div>
                                <div className="row"><TransactionsContent /></div>
                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>

                <button onClick={() => topFunction()} className="btn btn-danger btn-icon" id="back-to-top">
                    <i className="ri-arrow-up-line"></i>
                </button>

                <div id="preloader">
                    <div id="status">
                        <div className="spinner-border text-primary avatar-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>

                <ThemeSettings />

            </Fragment>
        )
    }
}
export default Dashboard
