import React, { Fragment } from 'react'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import NotificationModel from '../components/NotificationModel'
import ThemeSettings from '../components/ThemeSettings'
import Breadcrumb from '../components/Breadcrumb'
import BlocksContent from '../components/BlocksContent'

function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

class Blocks extends React.Component {
    render() {
        return (
            <Fragment>
                <div id="layout-wrapper">
                    <Header />
                    <NotificationModel />
                    <Sidebar />
                    <div className="vertical-overlay"></div>
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <Breadcrumb title="Blocks" route1="Tables" currentPage="Blocks" />
                                
                                <div className="row"><BlocksContent /></div>
                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>

                <button onClick={() => topFunction()} className="btn btn-danger btn-icon" id="back-to-top">
                    <i className="ri-arrow-up-line"></i>
                </button>

                <div id="preloader">
                    <div id="status">
                        <div className="spinner-border text-primary avatar-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>

                <ThemeSettings />

            </Fragment>
        )
    }
}
export default Blocks
