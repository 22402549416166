import React from 'react'
const PACKAGE_VERSION = require("../../package.json").version;

function Footer() {
  return (
    <footer className="footer">
    <div className="container-fluid">
        <div className="row">
            <div className="col-sm-6">
                <script>document.write(new Date().getFullYear())</script> v{PACKAGE_VERSION}
            </div>
            <div className="col-sm-6">
                <div className="text-sm-end d-none d-sm-block">
                    Developing...
                </div>
            </div>
        </div>
    </div>
    
</footer>
 
  )
}

export default Footer
