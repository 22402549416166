import React from 'react'

function UpgradeMessage() {
  return (
    <div className="row h-100">
    <div className="col-12">
        <div className="card">
            <div className="card-body p-0">
                <div className="alert alert-warning border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                    <i data-feather="alert-triangle" className="text-warning me-2 icon-sm"></i>
                    <div className="flex-grow-1 text-truncate">
                        Your free trial expired in <b>21</b> days.
                    </div>
                    <div className="flex-shrink-0">
                        <a href="pages-pricing.html" className="text-reset text-decoration-underline"><b>Upgrade</b></a>
                    </div>
                </div>

                <div className="row align-items-end">
                    <div className="col-sm-8">
                        <div className="p-3">
                            <p className="fs-16 lh-base">Upgrade your plan from a <span className="fw-semibold">Free
                                    trial</span>, to Premium Plan <i className="mdi mdi-arrow-right"></i></p>
                            <div className="mt-3">
                                <a href="pages-pricing.html" className="btn btn-success waves-effect waves-light">Upgrade
                                    Account!</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="px-3">
                            <img src="assets/images/user-illustarator-2.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* end card-body */}
        </div>
    </div>
    {/* end col */}
</div>
  )
}

export default UpgradeMessage